CookieConsent.run({
  guiOptions: {
    consentModal: {
      layout: 'box',
      position: 'middle center',
      equalWeightButtons: false,
      flipButtons: false,
    },
    preferencesModal: {
      layout: 'box',
      position: 'right',
      equalWeightButtons: false,
      flipButtons: false,
    },
  },
  onConsent: ({ cookie }) => {
    const isAnalyticsAccepted =
      cookie && cookie.categories && cookie.categories.includes('analytics');
    const isMarketingAccepted =
      cookie && cookie.categories && cookie.categories.includes('marketing');

    if (isAnalyticsAccepted) {
      // <!-- Google tag (gtag.js) -->
      const gtmScript1 = document.createElement('script');
      gtmScript1.dataset.category = 'analytics';
      gtmScript1.dataset.service = 'Google Analytics';
      gtmScript1.async = true;
      gtmScript1.src =
        'https://www.googletagmanager.com/gtag/js?id=G-JR2KLX604T';
      document.head.appendChild(gtmScript1);

      const gtmScript2 = document.createElement('script');
      gtmScript2.dataset.category = 'analytics';
      gtmScript2.dataset.service = 'Google Analytics';
      gtmScript2.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          dataLayer.push(arguments);
        }
        gtag('js', new Date());
        gtag('config', 'G-JR2KLX604T');
      `;
      document.head.appendChild(gtmScript2);

      const gtmScript3 = document.createElement('script');
      gtmScript3.dataset.category = 'analytics';
      gtmScript3.type = 'text/plain';
      gtmScript3.dataset.cookiescript = 'accepted';
      gtmScript3.dataset.cookiecategory = 'strict';
      gtmScript3.dataset.service = 'Google Analytics';
      gtmScript3.innerHTML = `
        (function (w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
          var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer'
              ? '&l=' + l
              : '';
          j.async = true;
          j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
          f
            .parentNode
            .insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', 'GTM-PL8ZSBR');
      `;
      document.head.appendChild(gtmScript3);
      // <!-- End Google Tag Manager -->
    }

    if (isMarketingAccepted) {
      const gtmScript3 = document.createElement('script');
      gtmScript3.dataset.category = 'analytics';
      gtmScript3.dataset.service = 'Google Analytics';
      gtmScript3.innerHTML = `
        (function (w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
          var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer'
              ? '&l=' + l
              : '';
          j.async = true;
          j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
          f
            .parentNode
            .insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', 'GTM-PL8ZSBR');
      `;
      document.head.appendChild(gtmScript3);
    }
  },
  categories: {
    necessary: {
      readOnly: true,
      autoClear: {
        cookies: [{ name: /__cf_bm/ }],
      },
    },
    functional: {
      enabled: true,
      autoClear: {
        cookies: [{ name: /__ca__chat/ }, { name: /^_ueuuid/ }],
      },
    },
    analytics: {
      enabled: true,
      autoClear: {
        cookies: [{ name: /^(_ga)/ }, { name: /^(_gcl)/ }],
      },
    },
    marketing: {
      enabled: true,
      autoClear: {
        cookies: [
          { name: /AnalyticsSyncHistory/ },
          { name: /UserMatchHistory/ },
          { name: /^_fbp/ },
          { name: /bcookie/ },
          { name: /li_sugr/ },
          { name: /lidc/ },
        ],
      },
    },
  },
  language: {
    default: 'en',
    autoDetect: 'browser',
    translations: {
      en: {
        consentModal: {
          title: 'Customize Your Preferences',
          description:
            'We value your privacy. We use cookies and other data to improve your experience. We may collect data for functional, statistical and marketing purposes. By clicking “Accept all”, you give us your consent for all or some of these purposes. You may withdraw your consent at any point by adjusting the settings in your browser, or by <a href="/contact">contacting us</a>. Please review and manage your preference below:',
          acceptAllBtn: 'Accept all',
          showPreferencesBtn: 'Manage preferences',
          footer:
            '<a href="/privacy-policy">Privacy Policy</a>\n<a href="/terms-of-use">Terms and Conditions</a>',
        },
        preferencesModal: {
          title: 'Customize Your Preferences',
          acceptAllBtn: 'Accept all',
          acceptNecessaryBtn: 'Accept necessary',
          savePreferencesBtn: 'Save preferences',
          closeIconLabel: 'Close',
          serviceCounterLabel: 'Service|Services',
          sections: [
            {
              description:
                'We value your privacy. We use cookies and other data to improve your experience. We may collect data for functional, statistical and marketing purposes. By clicking “Accept all”, you give us your consent for all or some of these purposes. By clicking “Accept necessary”, tracking and cookies will be disabled, except for those essential to the site’s operation. You may withdraw your consent at any point by adjusting the settings in your browser, or by <a href="/contact">contacting us</a>. Please review and manage your preference below:',
            },
            {
              title: 'Necessary <span class="pm__badge">Always Enabled</span>',
              description:
                'These cookies are essential for the website to function properly. They enable basic features such as page navigation and access to secure areas of the website. Without these cookies, the website cannot operate correctly.',
              linkedCategory: 'necessary',
            },
            {
              title: 'Functional',
              description:
                'Functional cookies enhance your browsing experience by remembering your preferences and settings. These cookies allow the website to offer more personalized features, such as remembering login details or language preferences.',
              linkedCategory: 'functional',
            },
            {
              title: 'Statistical',
              description:
                'Statistical cookies help us understand how visitors interact with our website by collecting and reporting information anonymously. This data helps us improve our website’s performance and user experience.',
              linkedCategory: 'analytics',
            },
            {
              title: 'Marketing',
              description:
                'Marketing cookies are used to track visitors across websites. These cookies aim to display ads that are relevant and engaging for the individual user. They also help measure the effectiveness of advertising campaigns.',
              linkedCategory: 'marketing',
            },
          ],
        },
      },
    },
  },
  disablePageInteraction: true,
});

// function logConsent(){
// Retrieve all the fields onFirstConsent
// const cookie = CookieConsent.getCookie();
// const preferences = CookieConsent.getUserPreferences();

// In this example we're saving only 4 fields
// const userConsent = {
//     consentId: cookie.consentId,
//     acceptType: preferences.acceptType,
//     acceptedCategories: preferences.acceptedCategories,
//     rejectedCategories: preferences.rejectedCategories
// };

// Send the data to your backend
// replace "/your-endpoint-url" with your API
// fetch('/your-endpoint-url', {
//     method: 'POST',
//     headers: {
//         'Content-Type': 'application/json'
//     },
//     body: JSON.stringify(userConsent)
// });
// }
